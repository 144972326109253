import { ReactElement } from 'react';

export default function ExpandIcon(): ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 160 160">
      <rect className="vertical" x="70" width="20" height="160" />
      <rect y="70" width="160" height="20" />
    </svg>
  );
}
