import { ReactElement, useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core';
import { IComponentAccordianFields, IFragmentAccordionSection } from 'types/contentful';
import RichText from '../UI/Richtext';
import ExpandIcon from './ExpandIcon';
import { MAX_SCREEN_WIDTH } from 'lib/consts';
import dynamic from 'next/dynamic';

// we have to dynamically import bc we change the className on client depending on screen size
// https://github.com/vercel/next.js/issues/7423
const AccordionSummary = dynamic(() => import('@material-ui/core/AccordionSummary'), {
  ssr: false,
});
const MuiAccordion = dynamic(() => import('@material-ui/core/Accordion'), {
  ssr: false,
});
const MuiAccordionDetails = dynamic(() => import('@material-ui/core/AccordionDetails'), {
  ssr: false,
});

export interface AccordionProps extends IComponentAccordianFields {
  standAlone: boolean;
}

interface StyleProps {
  isMobile: boolean;
}

const MAccordion = withStyles({
  root: {
    boxShadow: 'none',
    borderBottom: '1px solid black',
    '&:last-child': {
      borderBottom: 0,
    },
    '&$expanded': {
      margin: '0px 0px',
      '&:first-child': {
        marginTop: 0,
      },
    },
    '&:before': {
      display: 'none',
    },
  },
  expanded: {
    marginTop: 0,
  },
})(MuiAccordion);

const accordionSummary = makeStyles({
  root: {
    paddingLeft: 0,
    fontSize: '14px',
    minHeight: 48,
    '&$expanded': {
      minHeight: 48,
    },
  },
  expanded: {
    '& p': {
      backgroundColor: (props: StyleProps) => (props.isMobile ? 'transparent' : '#FFDD00'),
      fontWeight: (props) => (props.isMobile ? '700' : '400'),
    },
  },
  content: {
    fontSize: '14px',
    margin: '12px 0',
    '&$expanded': {
      margin: '12px 0',
    },
    '& P': {
      padding: '4px 16px',
      borderRadius: '9999px',
      marginLeft: '-16px',
    },
  },
  expandIcon: {
    transform: 'rotate(0deg)',
    '& .vertical': {
      transform: 'rotate(0deg)',
    },
    '&$expanded': {
      '& .vertical': {
        transform: 'rotate(90deg)',
      },
    },
  },
});

const AccordionDetails = withStyles({
  root: {
    paddingLeft: 0,
  },
})(MuiAccordionDetails);

export default function Accordion({ sections, standAlone = true }: AccordionProps): ReactElement {
  const [windowWidth, setWindowWidth] = useState(MAX_SCREEN_WIDTH);
  const isMobile = windowWidth <= 1024 ? true : false;
  const classes = accordionSummary({ isMobile });

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, [setWindowWidth]);

  return (
    <div className={`z-0 ${standAlone ? 'p-4 lg:px-16' : ''}`}>
      {sections.map((section: IFragmentAccordionSection, i: number) => {
        return (
          <MAccordion key={i} square>
            <AccordionSummary
              expandIcon={<ExpandIcon />}
              IconButtonProps={{ disableRipple: true }}
              classes={{ ...classes }}
            >
              <p>{section.fields.sectionHeading}</p>
            </AccordionSummary>
            <AccordionDetails>
              <RichText document={section.fields.sectionCopy} />
            </AccordionDetails>
          </MAccordion>
        );
      })}
    </div>
  );
}
